import { useCallback, useEffect, useState } from 'react';
import './Guestbook.css';
import GuestbookEntry from './GuestbookEntry';
import HeaderBackground from './HeaderBackground';
import background from './img/BANNER.png';
import arrow from './img/menu arrow.png';
import Loader from './Loader';
import { ApiProps, LanguageProps, extractResponse } from './util/Util';
import { PaginatedGuestbookEntry } from './api/AbenteuerpaketeApi';
import Divider from './Divider';
import TextFromApi from './TextFromApi';

function GuestbookPaginator(props: {onPageChange: (dir: 1 | -1) => any, displayForwards: boolean, displayBackwards: boolean}) {
  return <div className='guestbook-paginator'>
    {props.displayBackwards? <img alt="Zurück" src={arrow} style={{rotate: "90deg"}} className='nav-button' onClick={() => props.onPageChange(-1)}/>: <div/>}
    <div/>
    {props.displayForwards? <img alt="Weiter" src={arrow} style={{rotate: "-90deg"}} className='nav-button' onClick={() => props.onPageChange(1)}/>: <div/>}
  </div>
}

function Guestbook(props: ApiProps & LanguageProps) {
  const {
    api,
    lang,
    strings,
  } = props;

  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState<PaginatedGuestbookEntry>();
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState<any>();

  const onPageChange = useCallback((dir: 1 | -1) => setCurrentPage(p => p + dir), []);

  const getText = useCallback(() => extractResponse(api.ap.getGuestbookText({lang})), [api, lang]);

  useEffect(() => {
    setLoading(true);
    setEntries(undefined);
    setError(undefined);
    extractResponse(api.ap.getEntries(currentPage)).then(e => setEntries(e)).catch(setError).finally(() => setLoading(false));
  }, [currentPage, api]);

  const displayBackwards = currentPage > 1;
  const displayForwards = currentPage < (entries?.pages || 0);

  return (
    <div className="guestbook">
      <HeaderBackground background={background} title={strings.guestbook || "Guestbook"} />
      <TextFromApi strings={strings} request={getText} />
      <Divider />
      {loading? <Loader strings={strings} />: error? strings.error || "An error occurred": <>
        <GuestbookPaginator onPageChange={onPageChange} displayBackwards={displayBackwards} displayForwards={displayForwards} />
        {entries?.posts.map(e => <GuestbookEntry key={e.id} text={decodeURIComponent(e.content)} />)}
        <GuestbookPaginator onPageChange={onPageChange} displayBackwards={displayBackwards} displayForwards={displayForwards} />
      </>}
    </div>
  );
}

export default Guestbook;
