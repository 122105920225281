import HeaderBackground from "./HeaderBackground";
import background from './img/BANNER.png';
import { ApiProps, LanguageProps, extractResponse } from "./util/Util";
import './Games.css';
import LocalLink from "./LocalLink";
import Divider from "./Divider";
import { GamesOverview } from "./api/AbenteuerpaketeApi";
import { useCallback, useState } from "react";
import TextFromApi from "./TextFromApi";

function Games(props: ApiProps & LanguageProps) {
    const {
        api,
        lang,
        strings,
    } = props;

    const [gamesOverview, setGamesOverview] = useState<GamesOverview>();

    const getGamesOverview = useCallback(async () => {
        const overview = await extractResponse(api.ap.getGames({lang}));
        setGamesOverview(overview);
        return {
            content: overview.text,
        }
    }, [api, lang]);

    return <div>
        <HeaderBackground background={background} title={strings.games || "Games"} />
        <TextFromApi strings={strings} request={getGamesOverview} />
        <Divider />
        <div className="game-list">
            {gamesOverview?.games.map(g => <div key={g.id} >
                <LocalLink to={"/games/" + g.id}><img alt={g.name} aria-label={g.name} src={g.iconUrl} /></LocalLink>
                {/*<label>{g.name}</label>*/}
            </div>)}
        </div>
    </div>
}

export default Games;