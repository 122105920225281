import HeaderBackground from "./HeaderBackground";
import { Strings } from "./api/AbenteuerpaketeApi";
import background from './img/BANNER.png';

function NotFound(props: {strings: Strings}) {
    const {
        strings
    } = props;

    return <div style={{textAlign: "center"}}>
        <HeaderBackground background={background} title={strings.error} />
        <h1>
            {strings.error || "An error occured"}
        </h1>
    </div>
}

export default NotFound;