import './App.css';
import 'primeicons/primeicons.css';
import Guestbook from './Guestbook';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import Games from './Games';
import NotFound from './NotFound';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LANG_URL_PARAM_KEY, extractResponse, getRootLocation } from './util/Util';
import GameDetail from './GameDetail';
import Home from './Home';
import Imprint from './Imprint';
import Privacy from './Privacy';
import Conditions from './Conditions';
import { AbenteuerpaketeApi, Strings } from './api/AbenteuerpaketeApi';
import About from './About';
import Contact from './Contact';
import Loader from './Loader';

function App() {

  const api = useMemo(() => new AbenteuerpaketeApi({baseUrl: "/wp-json"}), []);

  const rootDir = getRootLocation();

  const [strings, setStrings] = useState<Strings>({});
  const [lang, setLang] = useState<string>();
  const [languages, setLanguages] = useState<string[]>();
  const [loading, setLoading] = useState(true);

  const onChangeLang = useCallback((lang: string) => {
    setLang(lang);
    const url = new URL(window.location.href);
    const browserLang = languages?.find(l => l.toLocaleLowerCase() == navigator.language.toLocaleLowerCase()) || languages?.find(l => l.split("-")[0].toLocaleLowerCase() === navigator.language.split("-")[0].toLocaleLowerCase());
    if (browserLang === lang) {
      url.searchParams.delete(LANG_URL_PARAM_KEY);
    }
    else {
      url.searchParams.set(LANG_URL_PARAM_KEY, lang);
    }
    window.history.replaceState(null, "", url);
  }, [languages]);

  useEffect(() => {
    async function refreshLanguages() {
      setLoading(true);
      let langSet = false;
      try {
        const languages = await extractResponse(api.ap.getLanguages());
        setLanguages(languages);
        let urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("redirectTo")) {
          urlParams = new URL(window.location.origin + decodeURIComponent(urlParams.get("redirectTo")!)).searchParams;
        }
        if (urlParams.has(LANG_URL_PARAM_KEY)) {
          const urlLang = urlParams.get(LANG_URL_PARAM_KEY)!;
          const lang = languages.find(l => l.toLocaleLowerCase() === urlLang.toLocaleLowerCase());
          if (lang) {
            setLang(lang);
            langSet = true;
          }
        }
        if (!langSet) {
          const browserLang = navigator.language;
          const lang = languages.find(l => l.toLocaleLowerCase() == browserLang.toLocaleLowerCase()) || languages.find(l => l.split("-")[0].toLocaleLowerCase() === browserLang.split("-")[0].toLocaleLowerCase());
          if (lang) {
            setLang(lang);
            langSet = true;
          }
        }
        if (!langSet) {
          const fallbackLang = await extractResponse(api.ap.getFallbackLang());
          setLang(fallbackLang.content);
        }
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
    refreshLanguages();
  }, [api]);

  useEffect(() => {
    async function refreshStrings() {
      if (lang) {
        setLoading(true);
        try {
          setStrings(await extractResponse(api.ap.getStrings({lang})));
        } catch (e) {
          console.error(e);
        }
        setLoading(false);
      }
    }
    refreshStrings();
  }, [api, lang]);

  return (
    <div className="App">
      <BrowserRouter >
        <Header lang={lang || ""} strings={strings} languages={languages || []} onChangeLanguage={onChangeLang} />
        {loading? <Loader strings={strings} />: <Routes>
          <Route path={rootDir} element={<Home/>} />
          <Route path={rootDir + 'guestbook'} element={<Guestbook lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'games'} element={<Games lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'games/:id'} element={<GameDetail lang={lang || ""} strings={strings} api={api} />} />
          <Route path={rootDir + 'about'} element={<About lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'imprint'} element={<Imprint lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'privacy'} element={<Privacy lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'conditions'} element={<Conditions lang={lang || ""} strings={strings} api={api}/>} />
          <Route path={rootDir + 'contact'} element={<Contact strings={strings} api={api}/>} />
          <Route path='*' element={<NotFound strings={strings} />} />
        </Routes>}
        <Footer strings={strings} />
      </BrowserRouter>
    </div>
  );
}

export default App;
