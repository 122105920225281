import { useEffect } from 'react';
import './HeaderBackground.css';

type Props = {
    background: string,
    title?: string,
}

function HeaderBackground(props: Props) {
    useEffect(() => {
        document.title = (props.title? props.title + " - ": "") + "Abenteuerpakete";
    }, [props.title]);

    return <div className="header-background">
        <img src={props.background} alt="header-background"/>
    </div>
}

export default HeaderBackground;