import { Navigate, useSearchParams } from "react-router-dom";
import HeaderBackground from "./HeaderBackground";
import background from './img/BANNER.png';
import { getRootLocation } from "./util/Util";
import { useMemo } from "react";

function Home() {
    const [searchParams] = useSearchParams();

    const redirectTo = useMemo(() => searchParams.get('redirectTo'), [searchParams]);

    if (redirectTo) {
        return <Navigate to={decodeURIComponent(redirectTo)} replace />
    }
    if (true) {
        return <Navigate to={getRootLocation() + "games" + (searchParams.size > 0? "?" + searchParams.toString(): "")} replace />
    }
    return <div>
        <HeaderBackground background={background} />
    </div>
}

export default Home;