import 'react-image-gallery/styles/css/image-gallery.css';

import { useParams } from "react-router-dom";
import HeaderBackground from "./HeaderBackground";
import { ApiProps, GamePropertyList, GamePropertyType, LanguageProps, extractResponse } from "./util/Util";
import NotFound from "./NotFound";
import { useEffect, useMemo, useState } from "react";
import './GameDetail.css';
import background from './img/BANNER.png';
import Divider from "./Divider";
import { GameDetails } from "./api/AbenteuerpaketeApi";
import Loader from "./Loader";
import PersonThumbnail from "./PersonThumbnail";
import ImageGallery from "react-image-gallery";
import { render } from 'react-dom';


function GameDetail(props: ApiProps & LanguageProps) {
    const {
        api,
        lang,
        strings,
    } = props;
    const { id } = useParams();

    const [game, setGame] = useState<GameDetails>();
    const [loading, setLoading] = useState(true);
    const [galleryIndex, setGalleryIndex] = useState<number>();

    const filteredGamePropertyList = useMemo(() => GamePropertyList.filter(p => game?.[p.key] !== undefined || (p.textKey && !!game?.[p.textKey])), [game]);

    const [displayedPropKey, setDisplayedPropKey] = useState<keyof GameDetails | undefined>(filteredGamePropertyList[0]?.key);

    const displayedProp = useMemo(() => {
        const prop = filteredGamePropertyList.find(g => g.key === displayedPropKey);
        if (prop) {
            return {...prop, value: game?.[prop.key]} as GamePropertyType;
        }
        return undefined;
    }, [filteredGamePropertyList, displayedPropKey]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            extractResponse(api.ap.getGame(id, {lang})).then(setGame).finally(() => setLoading(false));
        }
        else {
            setLoading(false);
        }
    }, [api, id, lang]);

    useEffect(() => {
        if (!filteredGamePropertyList.find(l => l.key === displayedPropKey)) {
            setDisplayedPropKey(filteredGamePropertyList[0]?.key);
        }
    }, [filteredGamePropertyList, displayedPropKey]);

    useEffect(() => {
        if (galleryIndex !== undefined && displayedProp?.displayType === "image" && displayedProp.enableGallery) {
            const div = document.createElement('div');
            div.className = "fullscreen gallery";

            render(<ImageGallery
                startIndex={galleryIndex}
                renderFullscreenButton={() => <button style={{fontSize: "x-large"}} onClick={() => setGalleryIndex(undefined)} type="button" className="image-gallery-icon image-gallery-fullscreen-button pi pi-window-minimize" aria-label="Open Fullscreen"/>}
                items={displayedProp.value?.map(v => {return {original: v}}) || []} />, div);

            document.documentElement.appendChild(div);

            return () => {document.documentElement.removeChild(div);}
        }
    })

    if(loading) {
        return <Loader strings={strings} />
    }

    if (!game) {
        return <NotFound strings={strings} />
    }

    return <div ref={d => d?.scrollIntoView({behavior: "smooth"})}>
        <HeaderBackground background={background} title={game.name} />
        <div className="game-detail">
            <img src={game.iconUrl} alt={game.id} />
            <h2>{game.name}</h2>
            <p className="plain" dangerouslySetInnerHTML={{__html: decodeURIComponent(game.text)}} />
            <Divider />
            <div className="game-properties">
                {filteredGamePropertyList.map(p => <div key={p.key}><label className={p.key === displayedPropKey? "active": undefined} onClick={e => {setDisplayedPropKey(p.key); const target = e.currentTarget; window.setTimeout(() => target.scrollIntoView({behavior: "smooth"}), 1)}} >{strings[p.stringsKey] || p.label}</label></div>)}
            </div>
            {displayedProp?.textKey && game?.[displayedProp.textKey] && <p className="plain" dangerouslySetInnerHTML={{__html: decodeURIComponent(game?.[displayedProp.textKey] || "")}} />}
            {displayedProp && <div className="game-prop">
                {displayedProp.displayType === "image" && displayedProp.value?.map((v, i) => <img key={"img-" + i} alt={"img-" + i} style={{cursor: displayedProp.enableGallery? "pointer": ""}} onClick={() => {if (displayedProp.enableGallery) setGalleryIndex(i)}} src={v} />)}
                {displayedProp.displayType === "person" && displayedProp.value?.map((v, i) => <PersonThumbnail key={"person-" + i} person={v} />)}
            </div>}
        </div>
    </div>
}

export default GameDetail;