import { useEffect, useState } from "react";
import Loader from "./Loader";
import { RawContent, Strings } from "./api/AbenteuerpaketeApi";

type Props = {
    request: () => Promise<RawContent>,
    strings: Strings,
}

function TextFromApi(props: Props) {
    const [text, setText] = useState<RawContent>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>();

    const {
        request,
        strings,
    } = props;

    useEffect(() => {
        setLoading(true);
        setError(undefined);
        setText(undefined);
        request().then(setText).catch(setError).finally(() => setLoading(false));
    }, [request]);

    if (loading) {
        return <Loader strings={strings} />
    }
    if (error || !text) {
        return <h2>{strings.error || "An error occured"}</h2>
    }
    return <p ref={p => p?.scrollIntoView({behavior: "smooth"})} className="plain" dangerouslySetInnerHTML={{__html: decodeURIComponent(text.content)}} />
}

export default TextFromApi;