import './GuestbookEntry.css';

type Props = {
    text: string,
}

function GuestbookEntry(props: Props) {
    return <div className='guestbook-entry'>
        <div>
            <div>
                {/*<button className="pi pi-flag-fill"/>*/}
            </div>
        </div>
        <p>{props.text}</p>
        <div><div/></div>
    </div>
}

export default GuestbookEntry;