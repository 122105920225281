import { LANGUAGE_BY_LOCALE } from "./Util";
import { getEmoji } from "language-flag-colors";
import { useMemo } from "react";

export type LanguageSelectProps = {
    languages?: string[],
    selection?: string,
    hideFlag?: boolean,
    hideName?: boolean,
    onChangeSelection: (key: string) => any,
}

export function LanguageSelect(props: LanguageSelectProps) {
    const {
        languages = Object.keys(LANGUAGE_BY_LOCALE),
        hideFlag,
        hideName,
        selection,
        onChangeSelection,
    } = props;

    const sortedLanguages = useMemo(() => {
        const langs = languages.filter(l => l in LANGUAGE_BY_LOCALE);
        langs.sort((a, b) => (LANGUAGE_BY_LOCALE as any)[a].localeCompare((LANGUAGE_BY_LOCALE as any)[b]));
        return langs;
    }, [languages]);

    return <select onChange={e => onChangeSelection(e.currentTarget.value)}>
        {sortedLanguages.map(l => <option value={l} selected={l === selection}>{hideFlag? "": getEmoji(l)}{!hideFlag && !hideName? " ": ""}{hideName? "": (LANGUAGE_BY_LOCALE as any)[l]}</option>)}
    </select>
}