import './Loader.css';
import { Strings } from './api/AbenteuerpaketeApi';

function Loader(props: {strings: Strings}) {
    const {
        strings,
    } = props;

    return <div className="loader">
        <div className='top back left'/>
        <div className='top back right'/>
        <div className='top front left'/>
        <div className='top front right'/>
        <div className='side negative back'/>
        <div className='side positive back'/>
        <div className='side positive front'>{strings.loading || "Loading"}</div>
        <div className='side negative front'>{strings.loading || "Loading"}</div>
        <div className='bottom'/>
    </div>
}

export default Loader;