import { Link, LinkProps, useSearchParams } from "react-router-dom";
import { getRootLocation } from "./util/Util";

function LocalLink(props: LinkProps & {discardSearchParams?: boolean}) {
    const [searchParams] = useSearchParams();

    const toPath = props.to.toString().startsWith("/")? getRootLocation() + props.to.toString().substring(1): props.to;
    
    return <Link {...props} to={toPath + (!props.discardSearchParams && searchParams.size > 0? "?" + searchParams.toString(): "")} />
}

export default LocalLink;