import "./PersonThumbnail.css";

import { Person } from "./api/AbenteuerpaketeApi";

function PersonThumbnail(props: {person: Person, gamePrefix?: boolean}) {
    const {
        person,
        gamePrefix,
    } = props;

    return <div className="person-thumbnail">
        <img src={person.picture} alt={person.name} />
        <div>
            <p>{person.name}</p>
            {person.roles.map(r => <p className="plain" key={r.gameId + "_" + r.roleName}>{(gamePrefix? r.gameName + ": ": "") + r.roleName}</p>)}
        </div>
    </div>
}

export default PersonThumbnail;