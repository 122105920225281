import { FormEvent, useCallback, useState } from "react";
import { ApiProps } from "./util/Util";
import { ContactForm, Strings } from "./api/AbenteuerpaketeApi";
import HeaderBackground from "./HeaderBackground";
import background from './img/BANNER.png';
import Divider from "./Divider";

function Contact(props: ApiProps & {strings: Strings}) {
    const {
        api,
        strings,
    } = props;
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stateMessage, setStateMessage] = useState<string>();

    const sendEmail = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.persist();
        e.preventDefault();
        setIsSubmitting(true);
        const target = e.currentTarget;
        try {
            const request = Object.fromEntries(new FormData(e.currentTarget).entries()) as unknown as ContactForm;
            const res = await api.ap.sendContactForm(request);
            if (!res.ok) {
                throw res.error;
            }
            setStateMessage(strings.messageSent || "Message successfully sent");
            // Clears the form after sending the email
            target.reset();
        } catch (e) {
            console.error(e);
            setStateMessage(strings.error || "An error occured");
        }
        
        setIsSubmitting(false);
        setTimeout(() => {
            setStateMessage(undefined);
        }, 5000); // hide message after 5 seconds
        
    }, [api, strings]);

    return <div>
        <HeaderBackground background={background} title={strings.contact} />
        <form onSubmit={sendEmail} encType='application/json' style={{display: "flex", flexDirection: "column", gap: "5px"}}>
            <label>{strings.name || "Name"}</label>
            <input type="text" name="name" />
            <label>{strings.email || "E-Mail"}*</label>
            <input type="email" name="email" required />
            <label>{strings.subject || "Subject"}*</label>
            <input type="text" name="subject" required />
            <label>{strings.message || "Message"}*</label>
            <textarea style={{minHeight: "200px"}} name="message" required />
            <input style={{alignSelf: "start"}} type="submit" value={strings.send || "Send"} disabled={isSubmitting} />
            {stateMessage && <p>{stateMessage}</p>}
        </form>
        <Divider />
    </div>;
}

export default Contact;