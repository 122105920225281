export const LANGUAGE_BY_LOCALE = {
    "af-ZA": "Afrikaans (South Africa)",
    "af": "Afrikaans",
    "ak-GH": "Akan (Ghana)",
    "ak": "Akan",
    "sq-AL": "Albanian (Albania)",
    "sq": "Albanian",
    "am-ET": "Amharic (Ethiopia)",
    "am": "Amharic",
    "ar-BH": "Arabic (Bahrain)",
    "ar-EG": "Arabic (Egypt)",
    "ar-SA": "Arabic (Saudi Arabia)",
    "ar-YE": "Arabic (Yemen)",
    "ar": "Arabic",
    "hy-AM": "Armenian (Armenia)",
    "hy": "Armenian",
    "as-IN": "Assamese (India)",
    "as": "Assamese",
    "az": "Azerbaijani",
    "bm-ML": "Bambara (Mali)",
    "bm": "Bambara",
    "be-BY": "Belarusian (Belarus)",
    "be": "Belarusian",
    "bn-BD": "Bengali (Bangladesh)",
    "bn-IN": "Bengali (India)",
    "bn": "Bengali",
    "bs-BA": "Bosnian (Bosnia and Herzegovina)",
    "bs": "Bosnian",
    "bg-BG": "Bulgarian (Bulgaria)",
    "bg": "Bulgarian",
    "my-MM": "Burmese (Myanmar [Burma])",
    "my": "Burmese",
    "chr-US": "Cherokee (United States)",
    "chr": "Cherokee",
    "zh-Hans": "Chinese (Simplified Han)",
    "zh-Hant": "Chinese (Traditional Han)",
    "zh": "Chinese",
    "hr-HR": "Croatian (Croatia)",
    "hr": "Croatian",
    "cs-CZ": "Czech (Czech Republic)",
    "cs": "Czech",
    "da-DK": "Danish (Denmark)",
    "da": "Danish",
    "nl-BE": "Dutch (Belgium)",
    "nl-NL": "Dutch (Netherlands)",
    "nl": "Dutch",
    "ebu": "Embu",
    "en-AU": "English (Australia)",
    "en-BZ": "English (Belize)",
    "en-CA": "English (Canada)",
    "en-HK": "English (Hong Kong SAR China)",
    "en-IN": "English (India)",
    "en-IE": "English (Ireland)",
    "en-JM": "English (Jamaica)",
    "en-NZ": "English (New Zealand)",
    "en-PH": "English (Philippines)",
    "en-SG": "English (Singapore)",
    "en-ZA": "English (South Africa)",
    "en-GB": "English (United Kingdom)",
    "en-US": "English (United States)",
    "en-ZW": "English (Zimbabwe)",
    "et-EE": "Estonian (Estonia)",
    "et": "Estonian",
    "ee-GH": "Ewe (Ghana)",
    "ee": "Ewe",
    "fo-FO": "Faroese (Faroe Islands)",
    "fo": "Faroese",
    "fil-PH": "Filipino (Philippines)",
    "fil": "Filipino",
    "fi-FI": "Finnish (Finland)",
    "fi": "Finnish",
    "fr-BE": "French (Belgium)",
    "fr-CA": "French (Canada)",
    "fr-FR": "French (France)",
    "fr-LU": "French (Luxembourg)",
    "fr-CH": "French (Switzerland)",
    "ff": "Fulah",
    "lg-UG": "Ganda (Uganda)",
    "lg": "Ganda",
    "ka-GE": "Georgian (Georgia)",
    "ka": "Georgian",
    "de-AT": "German (Austria)",
    "de-BE": "German (Belgium)",
    "de-DE": "German (Germany)",
    "de-LI": "German (Liechtenstein)",
    "de-LU": "German (Luxembourg)",
    "de-CH": "German (Switzerland)",
    "de": "German",
    "el-CY": "Greek (Cyprus)",
    "el-GR": "Greek (Greece)",
    "el": "Greek",
    "gu-IN": "Gujarati (India)",
    "gu": "Gujarati",
    "ha": "Hausa",
    "he-IL": "Hebrew (Israel)",
    "he": "Hebrew",
    "hi-IN": "Hindi (India)",
    "hi": "Hindi",
    "hu-HU": "Hungarian (Hungary)",
    "hu": "Hungarian",
    "is-IS": "Icelandic (Iceland)",
    "is": "Icelandic",
    "ig-NG": "Igbo (Nigeria)",
    "ig": "Igbo",
    "id-ID": "Indonesian (Indonesia)",
    "id": "Indonesian",
    "ga-IE": "Irish (Ireland)",
    "ga": "Irish",
    "it-IT": "Italian (Italy)",
    "it-CH": "Italian (Switzerland)",
    "ja-JP": "Japanese (Japan)",
    "ja": "Japanese",
    "kl-GL": "Kalaallisut (Greenland)",
    "kl": "Kalaallisut",
    "kn-IN": "Kannada (India)",
    "kn": "Kannada",
    "kk": "Kazakh",
    "km-KH": "Khmer (Cambodia)",
    "km": "Khmer",
    "rw-RW": "Kinyarwanda (Rwanda)",
    "rw": "Kinyarwanda",
    "kok-IN": "Konkani (India)",
    "kok": "Konkani",
    "ko-KR": "Korean (South Korea)",
    "ko": "Korean",
    "lag": "Langi",
    "lv-LV": "Latvian (Latvia)",
    "lv": "Latvian",
    "lt-LT": "Lithuanian (Lithuania)",
    "lt": "Lithuanian",
    "luy-KE": "Luyia (Kenya)",
    "luy": "Luyia",
    "mk-MK": "Macedonian (Macedonia)",
    "mk": "Macedonian",
    "mg-MG": "Malagasy (Madagascar)",
    "mg": "Malagasy",
    "ms-BN": "Malay (Brunei)",
    "ms-MY": "Malay (Malaysia)",
    "ms": "Malay",
    "ml-IN": "Malayalam (India)",
    "ml": "Malayalam",
    "mt-MT": "Maltese (Malta)",
    "mt": "Maltese",
    "gv": "Manx",
    "mr-IN": "Marathi (India)",
    "mr": "Marathi",
    "mer": "Meru",
    "ne-IN": "Nepali (India)",
    "ne-NP": "Nepali (Nepal)",
    "ne": "Nepali",
    "nd": "North Ndebele",
    "nb-NO": "Norwegian Bokmål (Norway)",
    "nb": "Norwegian Bokmål",
    "nn-NO": "Norwegian Nynorsk (Norway)",
    "nn": "Norwegian Nynorsk",
    "nyn": "Nyankole",
    "or-IN": "Oriya (India)",
    "or": "Oriya",
    "om-ET": "Oromo (Ethiopia)",
    "om": "Oromo",
    "ps-AF": "Pashto (Afghanistan)",
    "ps": "Pashto",
    "fa-AF": "Persian (Afghanistan)",
    "fa-IR": "Persian (Iran)",
    "fa": "Persian",
    "pl-PL": "Polish (Poland)",
    "pl": "Polish",
    "pt-BR": "Portuguese (Brazil)",
    "pt-PT": "Portuguese (Portugal)",
    "pt": "Portuguese",
    "pa": "Punjabi",
    "ro-RO": "Romanian (Romania)",
    "ro": "Romanian",
    "rm-CH": "Romansh (Switzerland)",
    "rm": "Romansh",
    "ru-MD": "Russian (Moldova)",
    "ru-RU": "Russian (Russia)",
    "ru-UA": "Russian (Ukraine)",
    "sg-CF": "Sango (Central African Republic)",
    "sg": "Sango",
    "sr-Cyrl": "Serbian (Cyrillic)",
    "sr-Cyrl-ME": "Serbian (Cyrillic, Montenegro)",
    "sr": "Serbian",
    "sn-ZW": "Shona (Zimbabwe)",
    "sn": "Shona",
    "ii-CN": "Sichuan Yi (China)",
    "ii": "Sichuan Yi",
    "si-LK": "Sinhala (Sri Lanka)",
    "si": "Sinhala",
    "sk-SK": "Slovak (Slovakia)",
    "sk": "Slovak",
    "sl-SI": "Slovenian (Slovenia)",
    "sl": "Slovenian",
    "so-SO": "Somali (Somalia)",
    "so": "Somali",
    "es-AR": "Spanish (Argentina)",
    "es-BO": "Spanish (Bolivia)",
    "es-CL": "Spanish (Chile)",
    "es-CO": "Spanish (Colombia)",
    "es-CR": "Spanish (Costa Rica)",
    "es-DO": "Spanish (Dominican Republic)",
    "es-EC": "Spanish (Ecuador)",
    "es-SV": "Spanish (El Salvador)",
    "es-GT": "Spanish (Guatemala)",
    "es-HN": "Spanish (Honduras)",
    "es-419": "Spanish (Latin America)",
    "es-MX": "Spanish (Mexico)",
    "es-NI": "Spanish (Nicaragua)",
    "es-PA": "Spanish (Panama)",
    "es-PY": "Spanish (Paraguay)",
    "es-PE": "Spanish (Peru)",
    "es-PR": "Spanish (Puerto Rico)",
    "es-ES": "Spanish (Spain)",
    "es-US": "Spanish (United States)",
    "es-UY": "Spanish (Uruguay)",
    "es-VE": "Spanish (Venezuela)",
    "sw-KE": "Swahili (Kenya)",
    "sw-TZ": "Swahili (Tanzania)",
    "sw": "Swahili",
    "sv-FI": "Swedish (Finland)",
    "sv-SE": "Swedish (Sweden)",
    "sv": "Swedish",
    "gsw": "Swiss German",
    "ta-IN": "Tamil (India)",
    "ta": "Tamil",
    "te-IN": "Telugu (India)",
    "te": "Telugu",
    "th-TH": "Thai (Thailand)",
    "th": "Thai",
    "ti-ER": "Tigrinya (Eritrea)",
    "ti": "Tigrinya",
    "tr-TR": "Turkish (Turkey)",
    "tr": "Turkish",
    "uk-UA": "Ukrainian (Ukraine)",
    "uk": "Ukrainian",
    "ur-IN": "Urdu (India)",
    "ur-PK": "Urdu (Pakistan)",
    "ur": "Urdu",
    "uz": "Uzbek",
    "vi-VN": "Vietnamese (Vietnam)",
    "vi": "Vietnamese",
    "cy-GB": "Welsh (United Kingdom)",
    "cy": "Welsh",
    "yo-NG": "Yoruba (Nigeria)",
    "yo": "Yoruba",
    "zu-ZA": "Zulu (South Africa)",
    "zu": "Zulu"
}