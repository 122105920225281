import './Footer.css';
import LocalLink from './LocalLink';
import { Strings } from './api/AbenteuerpaketeApi';

function Footer(props: {strings: Strings}) {
    const {
        strings,
    } = props;

    return <div className="footer">
        <div>
            <LocalLink to="/imprint">{strings.imprint || "Imprint"}</LocalLink>
            <LocalLink to="/contact">{strings.contact || "Contact"}</LocalLink>
            <LocalLink to="/privacy">{strings.privacyPolicy || "Privacy Policy"}</LocalLink>
            <a href='https://www.instagram.com/abenteuerpakete'><i className='pi pi-instagram' /></a>
            <a href='https://twitter.com/Abenteuerpakete'><i className='pi pi-twitter' /></a>
            <a href='https://www.youtube.com/channel/UC1hqiwuxemwYiMRDv4Kf4hA'><i className='pi pi-youtube' /></a>
        </div>
        <label>©2024 Abenteuerpakete. All Rights Reserved.</label>
    </div>
}

export default Footer;