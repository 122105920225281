import { AbenteuerpaketeApi, GameDetails, HttpResponse, Person, Strings } from "../api/AbenteuerpaketeApi";

const homepage = require("../../package.json").homepage;

export function getRootLocation() {
    return typeof homepage === "string"? (homepage + '/').replace(/\/\/+/, "/"): '/';
}

export async function extractResponse<T>(call: Promise<HttpResponse<T, any>>) {
    const res = await call;
    if (!res.ok) {
        console.error("Call to " + res.url + " failed: " + res.statusText, res);
        throw res.error;
    }
    return res.data;
}

export function snakeToCamelCaseKeys(obj: any): any {
    if (typeof obj === "object" && obj !== null) {
        if (Array.isArray(obj)) {
            return obj.map(e => typeof e === "object" && e !== null? snakeToCamelCaseKeys(e): e);
        }
        else {
            var newObject: any = {}
            for (let key in obj) {
                newObject[snakeToCamelCase(key)] = snakeToCamelCaseKeys(obj[key]);
            }
            return newObject;
        }
    }
    return obj;
}

export function snakeToCamelCase(snake: string): string {
    let camel = ""
    let nextIsUpper = false;
    for (let char of snake) {
        if (char !== "_") {
            if (nextIsUpper) {
            camel += char.toLocaleUpperCase();
            } else {
                camel += char;
            }
        }
        nextIsUpper = char === "_";
    }
    return camel;
}

type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V? P: never]: any
}

export type GamePropertyType = {label: string, stringsKey: keyof Strings} & ({
    key: KeyOfType<GameDetails, string[] | undefined>,
    displayType: "image",
    value?: string[],
    textKey?: KeyOfType<GameDetails, string | undefined>,
    enableGallery?: boolean,
} |
{
    key: KeyOfType<GameDetails, Person[] | undefined>,
    displayType: "person",
    value?: Person[],
    textKey?: KeyOfType<GameDetails, string | undefined>,
})

export const GamePropertyList: GamePropertyType[] = [
    {
        key: "team",
        label: "Team",
        stringsKey: "team",
        displayType: "person",
        textKey: "teamText",
    },
    {
        key: "gallery",
        label: "Gallery",
        stringsKey: "gallery",
        displayType: "image",
        enableGallery: true,
    },
    {
        key: "voices",
        label: "Voices",
        stringsKey: "voices",
        displayType: "person",
        textKey: "voicesText",
    },
    {
        key: "sponsors",
        label: "Sponsors",
        stringsKey: "sponsors",
        displayType: "image",
        textKey: "sponsorsText",
    },
]

export type ApiProps = {
    api: AbenteuerpaketeApi<unknown>,
}

export type LanguageProps = {
    lang: string,
    strings: Strings,
}

export const LANG_URL_PARAM_KEY = "lang";