import Divider from "./Divider";
import HeaderBackground from "./HeaderBackground";
import TextFromApi from "./TextFromApi";
import background from './img/BANNER.png';
import { ApiProps, LanguageProps, extractResponse } from "./util/Util";

function Conditions(props: ApiProps & LanguageProps) {
    const {
      api,
      lang,
      strings,
    } = props;
    
    return <div>
        <HeaderBackground background={background} title={strings.termsAndConditions || "Terms and Conditions"} />
        <TextFromApi strings={strings} request={() => extractResponse(api.ap.getGeneralTermsAndConditions({lang}))} />
        <Divider />
    </div>
}

export default Conditions;