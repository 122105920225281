import Divider from "./Divider";
import HeaderBackground from "./HeaderBackground";
import TextFromApi from "./TextFromApi";
import background from './img/BANNER.png';
import { ApiProps, LanguageProps, extractResponse } from "./util/Util";

function Privacy(props: ApiProps & LanguageProps) {
    const {
      api,
      lang,
      strings,
    } = props;
    
    return <div>
        <HeaderBackground background={background} title={strings.privacyPolicy || "Privacy Policy"} />
        <TextFromApi strings={strings} request={() => extractResponse(api.ap.getPrivacyPolicy({lang}))} />
        <Divider />
    </div>
}

export default Privacy;