import { useState } from 'react';
import './Header.css';
import LocalLink from './LocalLink';
import background from './img/BANNER.png';
import MediaQuery from 'react-responsive';
import { LanguageProps } from './util/Util';
import { LanguageSelect } from 'language-select';

function Header(props: LanguageProps & {languages: string[], onChangeLanguage: (lang: string) => void}) {
    const {
        lang,
        strings,
        languages,
        onChangeLanguage,
    } = props;

    const [showLinks, setShowLinks] = useState(false);

    return <div className='header' id='header'>
        <img src={background} alt="banner"/>
        <div>
            {languages.length > 1? <LanguageSelect onChangeSelection={onChangeLanguage} selection={lang} languages={languages} hideName />: <div/>}
            <MediaQuery minWidth={1100} >
                <h1>
                    Abenteuer
                    <br/>
                    pakete.de
                </h1>
                <div>
                    <LocalLink to="/">{strings.home || "Home"}</LocalLink>
                    <a href="http://abenteuerpaketeshop.de">{strings.shop || "Shop"}</a>
                    <LocalLink to="/guestbook">{strings.guestbook || "Guestbook"}</LocalLink>
                    <LocalLink to="/about">{strings.aboutUs || "About Us"}</LocalLink>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={1100}>
                <h1/>
                <div>
                    <i className='pi pi-bars menu' onClick={() => setShowLinks(true)} />
                </div>
                {showLinks && <div className='fullscreen header-links'>
                    <i className='pi pi-arrow-right' onClick={() => setShowLinks(false)} />
                    <LocalLink onClick={() => setShowLinks(false)} to="/">{strings.home || "Home"}</LocalLink>
                    <a href="http://abenteuerpaketeshop.de">{strings.shop || "Shop"}</a>
                    <LocalLink onClick={() => setShowLinks(false)} to="/guestbook">{strings.guestbook || "Guestbook"}</LocalLink>
                    <LocalLink onClick={() => setShowLinks(false)} to="/about">{strings.aboutUs || "About Us"}</LocalLink>
                </div>}
            </MediaQuery>
        </div>
    </div>
}

export default Header;